import React from 'react'
import { Button } from './Button';
import { Spotify } from 'react-spotify-embed';
import './HeroSection.css';
import '../App';


function HeroSection() {
    return (
        <div className='hero-container'>
          <div className='social-icons'>
          <a
              class='social-icon-link spotify'
              href='https://open.spotify.com/artist/46GltPuUxikh6VuxhJSB1Z'
              target='_blank'
              rel='noreferrer'
              aria-label='Spotify'
            >
              <i class='fab fa-spotify' />
            </a>   
            <a
              class='social-icon-link applemusic'
              href='https://music.apple.com/us/artist/boom-chr-paige/80850403'
              target='_blank'
              rel='noreferrer'
              aria-label='Apple Music'
            >
              <i class='fab fa-apple' />
            </a>  
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/boomchrpaige/'
              target='_blank'
              rel='noreferrer'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
            <a
              class='social-icon-link youtube'
              href='https://www.youtube.com/c/SeriousBoom'
              target='_blank'
              rel='noreferrer'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </a>
            <a
              class='social-icon-link twitter'
              href='https://twitter.com/boomchrpaige'
              target='_blank'
              rel='noreferrer'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </a>
            <a
              class='social-icon-link soundcloud'
              href='https://soundcloud.com/boomchrpaige'
              target='_blank'
              rel='noreferrer'
              aria-label='SoundCloud'
            >
              <i class='fab fa-soundcloud' />
            </a>
            <a
              class='social-icon-link bandcamp'
              href='https://boomchrpaige.bandcamp.com'
              target='_blank'
              rel='noreferrer'
              aria-label='Bandcamp'
            >
              <i class='fab fa-bandcamp' />
            </a>      
          
    
          </div>
            <h1>Manufactured Safety</h1>
            <p>Six tracks, including 3 new remixes from Twitch, Sigint & Boom. 2 new unreleased tracks.</p>
            <Spotify link="https://open.spotify.com/album/3FHjxDu7wr9OQxw4XBI8qq" width='60%' height='352' />
            <h1>Ring Ring EP</h1>
            <p>Seven tracks, including 5 new remixes from Twitch, Sigint & Boom. 2 new unreleased tracks.</p>
            <Spotify link="https://open.spotify.com/album/4MxJhRMEb4R8rjoGcYfAs4" width='60%' height='352' />
            <h4>HUAC-A-Mole LP</h4>
            <p>Full length album with a balance of techno, industrial, synthwave and electro.</p>
            <Spotify link="https://open.spotify.com/album/62bk6xWqrQAV5luCX7KMOw" width='60%' height='352'/>
            <div className='hero-btns'>
            <Button 
                    className='btns' 
                    buttonStyle='btn--primary' 
                    buttonSize='btn-large'
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://open.spotify.com/album/4MxJhRMEb4R8rjoGcYfAs4';
                        }}
                    >
                        STREAM ON SPOTIFY <i className='far fa-play-circle' />
            </Button>
            <Button 
                    className='btns' 
                    buttonStyle='btn--primary' 
                    buttonSize='btn-large'
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://boomchrpaige.bandcamp.com/album/ring-ring-single';
                        }}
                    >
                        PURCHASE ON BANDCAMP <i className='far fa-play-circle' />
            </Button>
            <Button 
                    className='btns' 
                    buttonStyle='btn--primary' 
                    buttonSize='btn-large'
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://music.amazon.com/albums/B0BQ432GQG?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_qoUmsxlarJWCcw6X8VxYuNxCp';
                        }}
                    >
                        LISTEN AT AMAZON <i className='far fa-play-circle' />
            </Button>
            <Button
                    className='btns' 
                    buttonStyle='btn--outline' 
                    buttonSize='btn-large'
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://music.apple.com/us/artist/boom-chr-paige/80850403';
                        }}
                    >
                        LISTEN ON APPLE MUSIC <i className='far fa-play-circle' />
            </Button>

                
            </div>
            <div>
      </div>
        </div>
    )
}

export default HeroSection;
