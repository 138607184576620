import React from 'react';
import '../../App.css';
import Cards from '../Cards';


 function Links() {
    return (
    <>
        <Cards />
    </>
    );
}

export default Links;
