import React from 'react';
import './Cards2.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check out these EPIC Destinations!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/manufactured_safety_1.png'
              text='Manufactured Safety'
              label='Remixes'
              path='/remixes'
            />
            <CardItem
              src='images/img-2.jpg'
              text='Ring Ring'
              label='Remixes'
              path='/remixes'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-3.jpg'
              text='HUAC-A-Mole'
              label='Album'
              path='/album'
            />
            <CardItem
              src='images/img-4.jpg'
              text='Membranes'
              label='Album'
              path='/album'
            />
            <CardItem
              src='images/img-8.jpg'
              text='The Psychal'
              label='Online'
              path='/online'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;