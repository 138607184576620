import React from 'react';
import '../../App.css';

function About() {
    return (
        <div className='generalContainer'>
        <h3 className='about'>Boom Chr Paige</h3>
        <p className='about_text'>Boom chr <i>/chür | tʃɜr/</i> Paige is an American producer, electronic artist, multi-timbral musician and DJ living in Philadelphia.</p>
        <p className='about_text'>Boom has spent life behind the keyboards, guitars, and consoles of studios across the globe, both analog and digital, crafting a high-impact sound that pushes genre boundaries. His love for reaching into uncharted territory has earned him spots on scores of compilations and remixes over the years releasing worldwide with labels such as Metropolis, Universal, COP, Cargo, Teknofonic and more.</p>
        <p className='about_text'>He has been re-energized since 2021, continuing a path of further exploration into electronic music using dreamlike ambience in the vinyl and digital release of <a href='https://boomchrpaige.bandcamp.com/album/membranes' target='_blank' rel='noreferrer'>‘Membranes’</a>, his own personal document of the COVID-19 pandemic.  In 2022, he emerged with new hypnotic grooves and his signature layered melodies on <a href='https://open.spotify.com/album/62bk6xWqrQAV5luCX7KMOw' target='_blank' rel='noreferrer'>‘HUAC-A-Mole’</a>, spawning dance floor ready singles <a href='https://open.spotify.com/album/4MxJhRMEb4R8rjoGcYfAs4' target='_blank' rel='noreferrer'>‘Ring Ring’</a> and ‘Manufactured Safety’.</p>
        <h4 className='about'>The tl;dr</h4>
        <p className="about_text_small">Growing up in a family that favored the arts (being the son of a drummer, nephew of a radio DJ, and grandson of an opera singer), Boom’s childhood included studies under a handful of jazz guitarists and one Argentinian-born pianist in the Southwestern U.S. Never lacking in exposure to music, he was enticed by the synthwave sounds of the 80s and the power and energy of punk & thrash music, Boom honed his talent as a guitarist, earning top honors at multiple classical and jazz guitar competitions in the Tex-Mex region of the States. While traveling through Europe, he was inspired by the subversive arts after attending a Drahdiwaberl performance in Vienna in the late 80s.</p>
        <p className="about_text_small">Boom befriended Daveoramma 6.0 of Azure/The Watchmen in 1991 to fill in guitar voids on a handful of tracks (for both bands). With Boom's growing involvement, The Watchmen transformed into Society Burning, quickly becoming an underground phenomenon and an active part of Cargo Records’ infamous Re-Constriction Records roster. </p>
        <p className="about_text_small">After Tactiq, Boom extended his reach into the musical community by remixing tracks for several bands in the underground electronic and industrial genre, including Leaether Strip, Purr Machine, UCNX, Everything Goes Cold, The Clay People, Battery, and Idiot Stare.</p>
        <p className="about_text_small">After spending most of the aughts remixing others, he rounded the decade out by finalizing and releasing Society Burning’s lost work ‘Internal Combustion’ with his former bandmates. Directly afterward, Boom switched hats and began releasing his own techno inspired dance floor music, performing live and dj’ing regularly around the Philadelphia region. During this time, he dropped the ‘chr Paige’ from his artist moniker and released the EP ‘Sept’. </p>
        <p className="about_text_small">The EP landed him in the sights of New York’s Teknofonic Recordings, who would go on to support seven more releases from Boom, including 2015’s ‘House for Serial Killers’ EP. </p>
        <p className="about_text_small">Boom moved to Hereford, England for a time and then to Metro Washington D.C. late in the decade, just prior to the COVID-19 pandemic. His experiences traveling, and the exasperation of the lockdowns helped Boom define his next release ‘Membranes’ in 2021. Written purposefully with the vinyl LP format in mind, the album was full of dreamlike electronics and ambience, ‘documenting’ two distinct timeframes of the pandemic. </p>
        <p className="about_text_small">Not one to sit still for too long, Boom edged his way back toward the dance floor by 2022, this time fascinated by the shape of American political views post 2020 and how little ideology has changed in this country since the Second World War. Using reference from the governmental bodies such as HUAC, Boom changed the beat, but also reached back into his industrial rock roots to add elements of noise and energy to the mix. He released ‘HUAC-A-Mole’ (a title he insists you can pronounce any way you want) on Halloween in 2022. Tracks ranged from punchy dance floor numbers (‘Ring Ring’, ‘My Perception of Your Vision’) to bomb dropping destruction (‘Expiration Date’ & ‘Manufactured Safety’).</p>
        <p className="about_text_small">For 2023, Boom has been calling back on favors from his friends to help release remix EP’s of some of his favorites from the HUAC-A-Mole album. Early in the year brought ‘Ring Ring’ which included reissues and new tracks as well as 4 new interpretations of the original. April will be bringing ‘Manufactured Safety’ which pushes the boundaries of drum’n’bass, powernoise, and gabber house. In late May, ‘Open Conversation’ will hit the digital streaming circuit. </p>
        </div>

    );
}

export default About;
